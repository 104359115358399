// Body
$body-bg: #f8fafc;
$primary: #444444;
$font-white: #FFFFFF;
$white: #FFFFFF;
$border: #707070;
$danger: #A21F1F;
$agree: #2aaa5c;

// Typography
$font-family-sans-serif: "neue-haas-grotesk-display", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
