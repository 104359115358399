@import url('https://use.typekit.net/veu6bqa.css');
@import "/node_modules/toastify-js/src/toastify.css";
@import "~@fortawesome/fontawesome-pro/css/all";
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: auto !important;
    }
}
html{
    overflow-x: hidden;
}
#general-body{
    height: 100vh;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
}
.btn{
    border-radius: 22pt !important;
    letter-spacing: 1.5pt;
}
.btn-border-primary{
    border-color: $border;
    color: $border;
    &:hover {
        background-color: $border;
        color: $white;
    }
}
.btn-border-danger{
    border-color: $danger;
    color: $danger;
    &:hover {
        background-color: $danger;
        color: $white;
    }
}
.bg-dark{
    background-color: #000000 !important;
}
.form-control{
    padding: 1px 2%;
}
.input-group-append{
    background-color: transparent;
    border: none;
    cursor: pointer;
    .input-group-text{
        background-color: transparent;
        border: none;
        border-radius: 0%;
        border-bottom: 1px solid $primary;
    }
}
input:active,
input:focus,
input:hover,
input:visited,
input.active,
input.focus,
input.hover,
input.visited
select:active,
select:focus,
select:hover,
select:visited,
select.active,
select.focus,
select.hover,
select.visited {
    box-shadow: unset !important;
    outline: 0 !important;
}
.curve-input{
    border-radius: 13pt !important;
}
.button-border-input{
    border: none;
    border-radius: 0%;
    border-bottom: 1px solid #707070;
}
.title-font{
    font-size: clamp(16px, 18pt, 20pt);
    letter-spacing: 3pt;
}
.sub-title-font{
    font-size: clamp(10pt, 13pt, 16pt);
    letter-spacing: 2pt;
}
.tab-font{
    letter-spacing: 1.6pt;
    font-size: clamp(7pt, 2vw, 10pt);
}
.normal-font{
    letter-spacing: 1pt;
}
.input-span{
    float: right;
    margin-right: 0px;
    margin-top: 0px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;
    cursor: pointer;
    transition: .3s;
}
.dropdown{
    .dropdown-btn{
        padding-top: ($spacer * .5) !important;
        padding-bottom: ($spacer * .5) !important;
        padding-left: $spacer !important;
        padding-right: $spacer !important;
        border-radius: 8pt;
        border: 1px solid $border;
        background-color: $white;
        text-align: left;
        letter-spacing: 1pt;
        .dropdown-btn-input{
            border: none;
            width: fit-content;
            
        }
        .dropdown-btn-text{
            width: 95%;
            text-overflow: ellipsis;
            overflow: hidden;
            
        }
    }
    .dropdown-menu{
        width: 96% !important;
        .dropdown-item{
            cursor: pointer;
            letter-spacing: 1pt;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0 auto;
        }
    }
    
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #BCBCBC;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #BCBCBC;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #BCBCBC;
}
input[type='checkbox']  {
    cursor: pointer;
}
select{
    background-color: transparent !important;
}
select.decoratedd option:hover {
    background-color: yellow;
}
body::-webkit-scrollbar-track, select::-webkit-scrollbar-track, .Scrollable::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
  background-color: #ffffff;
}

body::-webkit-scrollbar, select::-webkit-scrollbar, .Scrollable::-webkit-scrollbar
{
  width: 1px;
  background-color: #ffffff;
}

body::-webkit-scrollbar-thumb, select::-webkit-scrollbar-thumb, .Scrollable::-webkit-scrollbar-thumb
{
  background-color: #cccccc;
}
.Scrollable2::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    background-color: #F6F6F6;
}

.Scrollable2::-webkit-scrollbar
{
    width: 1px;
    background-color: #F6F6F6;
}

.Scrollable2::-webkit-scrollbar-thumb
{
    background-color: #cccccc;
}

.scrollable-transparent::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar-thumb
{
    background-color: #fff;
}
.Scrollable, .Scrollable2, .scrollable-transparent{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .fa{
    transition: .3s;
    &:hover{
        transform: scale(1.05);
    }
  }
  
  .bg-border{
    background-color: #F2F2F2;
  }
  
.pagination{
    .page-item{
        cursor: pointer;
    }
}
.fa{
    cursor: pointer;
}
.fa-trash-can {
    color: #A21F1F !important;
}
.sheet{
    margin: 0;
    padding: 0;
    border: 1px solid #000;
    display: grid;
    grid-template-columns: 1fr;
    .piece{
        grid-row-start: 1;
        grid-column-start: 1;
        border: 1px solid #000;
        position: relative;
        z-index: 2;
        .piece-text{
            writing-mode: vertical-rl;
            text-orientation: upright;
            margin: 0 auto;
            padding: 0;
            height: 100%;
            text-align: center;
            color: #444;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: .9em;
        }
    }
    .line-guide{
        grid-row-start: 1;
        grid-column-start: 1;
        border: 1px dotted #fff;
        position: relative;
        z-index: 1;
    }
}
//center div
.centered{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}